/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from 'react'
import axios from 'axios'

import { graphql, useStaticQuery, Link } from 'gatsby'
import RenderForm from 'gatsby-plugin-cloudofertas'

/* COMPONENTS */
import Pagina from '../components/Pagina'
import Loading from '../components/Loader/loader'

export default function Contato () {
  const data = useStaticQuery(
    graphql`
      query {
        pagina: contentfulPagina(path: { eq: "/contato/" }) {
          path
          metaTitle
          metaDescription
          tags
        }
        cloudOfertasForm(name: {eq: "CONTATO"}) {
          id
          formTypeField {
            name
            type
            required
            style
            length
            label
            formLov {
              formLovData {
                id
                value
                createdDate
              }
            }
          }
        }
      }
    `
  )
  const urlApi = `${process.env.GATSBY_CLOUDOFERTAS_API_URL}/sites/${process.env.GATSBY_CLOUDOFERTAS_SITE_ID}/form-data/${data.cloudOfertasForm.id}/`
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [disableBtn, setDisableBtn] = useState(false)

  // const onSubmit = async (data) => {
  //   setDisableBtn(true)
  //   setLoading(true)
  //   try {
  //     const config = {
  //       headers: {
  //         accessToken: process.env.GATSBY_CLOUDOFERTAS_SITE_KEY
  //       }
  //     }

  //     const result = await axios.post(urlApi, data, config)
  //     setLoading(false)
  //     setSubmitSuccess(true)

  //     console.log('result:', result)
  //   } catch (err) {
  //     console.log('ERRO: ', err)
  //   }
  // }
  return (
    <Fragment>
      <Loading loader={loading}/>
      <Pagina pagina={data.pagina} />
      <div>
        <section className='theme--quaternary'>
          <div id='img-top' className='w-100'></div>
          <div className='pb-3'>
            <div className='container pt-5 '>
              <div className='col-12 p-0'>
                <h2 className='theme-primary'>
                  FALE COM A GENTE!
                </h2>
                <span>Queremos escutar você, por esse canal você pode tirar suas dúvidas, fazer reclamações ou elogios. Atendimento em horário comercial, para preços e pedidos por gentileza entre em contato com uma de nossas lojas. Encontre o contato da <Link to='/lojas/'>loja mais próxima aqui</Link>.</span>
              </div>
            </div>
            <div>
              { data.cloudOfertasForm.formTypeField && submitSuccess === false &&
                <RenderForm
                  disabledButton={disableBtn}
                  setSuccessSubmit={setSubmitSuccess}
                  formData={data.cloudOfertasForm}
                  placeholder={true}
                  containerStyle='container'
                  rowStyle='row'
                  inputStyle='custom-form d-flex flex-column mt-3'
                  btnContainerStyle='custom-form col-12'
                  btnStyle='btn btn-block theme--secundary btn-outline-#1A3797 theme-border-2 text-white my-3'
                  btnName='ENVIAR'
                />
              }
              {submitSuccess &&
              <div className='container mt-4'>
                <div className='row'>
                  <div className='col'>
                    <div id="alertSucesso" className="alert alert-success" role="alert">
                      Obrigado por entrar em contato com a gente. Responderemos em até 48h.
                    </div>
                  </div>
                </div>
              </div>
              }
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  )
}
